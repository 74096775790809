body {
    margin: 0;
    padding: 0;
    font-family: "Gotham SSm A", "Gotham", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

/* from TfNSW */
.label, label, legend {
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: 500;
    color: #222;
    display: block;
    margin-top: 0;
    margin-bottom: .625rem;
}